// const requireModule = require.context('.', false, /\.vue$/); // Get svg files inside modules folder

export const steps = [
  {
    title: 'Define Objects and Static Regions',
    component: 'DefineObjectsAndStaticRegion',
    nextButton: { text: 'Label Objects', icon: 'ArrowRightOutlined' },
  },
  {
    title: 'Label Objects',
    component: 'LabelObjects',
    key: 'label_objects',
    nextButton: {
      text: 'Label Static Regions',
      icon: 'ArrowRightOutlined',
    },
    prevButton: {
      text: 'Define Objects and Static Regions',
      icon: 'ArrowLeftOutlined',
    },
  },
  {
    title: 'Label Static Regions',
    component: 'LabelStaticRegions',
    key: 'label_static_regions',
    nextButton: {
      text: 'Label Plane Regions',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Objects', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Label Plane Regions',
    component: 'LabelPlaneRegions',
    key: 'label_plane_regions',
    nextButton: {
      text: 'Associate Steps',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Static Regions', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Associate Steps',
    component: 'AssociateSteps',
    key: 'associate_steps',
    nextButton: {
      text: 'Associate Relation',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Plane Regions', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Associate Relation',
    component: 'RelationSteps',
    key: 'relation_steps',
    nextButton: {
      text: 'Done',
      icon: 'CheckOutlined',
    },
    prevButton: { text: 'Associate Steps', icon: 'ArrowLeftOutlined' },
  },
];

export const columns = [
  {
    title: 'Thumbnail',
    dataIndex: 'thumbnail_path',
    key: 'thumbnail_path',
  },
  {
    title: 'Filename',
    dataIndex: 'fileName',
    key: 'fileName',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];

export const getTaskODSteps = (is3D) => {
  if (is3D) return steps
  return steps.filter(s => s.key !== 'label_plane_regions').map(s =>
    s.key === 'label_static_regions'
      ? { ...s, nextButton: { ...s.nextButton, text: 'Associate Steps' } }
      : s.key === 'associate_steps'
        ? { ...s, prevButton: { ...s.prevButton, text: 'Label Static Regions' } }
        : s
  );
}

// export const getComponents = () => {
//   const components = {};

//   requireModule.keys().forEach((filePath) => {
//     if (filePath === './ObjectDetection.vue') return;

//     const fileName = filePath.replace('.vue', '').replace('./', '');

//     components[`${fileName}`] = requireModule(filePath).default['__file'];
//   });
//   return components;
// };
