<template>
  <a-row
    class="h-100 d-flex justify-content-around align-items-center"
    :gutter="[24, 0]"
  >
    <a-col span="12" class="h-100 d-flex flex-column">
      <a-card
        hoverable
        :body-style="{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }"
        size="small"
        class="annotation-image-card d-flex flex-column flex-grow-1"
      >
        <a-spin
          v-if="imageLoading"
          size="large"
          tip="Loading Image..."
          class="m-auto"
        />

        <a-result
          v-else-if="!imageLoading && !image"
          id="not-found-label-image"
          title="Image Not Found!"
          class="m-auto"
        >
          <template #icon>
            <exclamation-circle-outlined class="text-danger" />
          </template>
        </a-result>

        <template #title>
          <a-space>
            <div style="display: flex;">
              <div v-if="selectSmartSwitch" style="padding-right: 3px;">
                Smart
              </div>
              <div v-else style="padding-right: 3px;">
                Manual
              </div>
              Draw
            </div>
            <a-switch v-model:checked="selectSmartSwitch" />

            <a-tooltip v-if="!selectSmartSwitch">
              <template #title>Draw Polygon</template>
              <a-button
                type="primary"
                shape="circle"
                :disabled="imageLoading"
                class="my-2 mx-2"
                :style="{
                  backgroundColor: isAddingPolygon ? 'blue' : 'white',
                  color: isAddingPolygon ? 'white' : 'blue'
                }"
                @click="addNewPolygon"
              >
                <template #icon>
                  <edit-outlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip v-if="selectSmartSwitch">
              <template #title>Draw Shape</template>
              <a-button
                id="region-modal-btn"
                shape="circle"
                type="primary"
                class="my-2 mx-2"
                :disabled="imageLoading"
                :style="{
                  backgroundColor: isAddingRectangle ? 'blue' : 'white',
                  color: isAddingRectangle ? 'white' : 'blue'
                }"
                @click="setNewObjectConfigToDraw"
              >
                <template #icon>
                  <border-outlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip v-if="selectSmartSwitch">
              <template #title>{{
                generatingImageEmbedding || 'Segment Object'
              }}</template>
              <a-button
                id="region-modal-btn"
                shape="circle"
                type="primary"
                class="my-2 mx-2"
                :disabled="disableSegmentTrack || generatingImageEmbedding"
                :loading="loadingSemtrack"
                :style="{
                  backgroundColor: 'white',
                  color: 'blue'
                }"
                @click="segmentTracking"
              >
                <template #icon>
                  <HighlightOutlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip>
              <template #title>Apply Previous Labels</template>
              <a-button
                id="region-modal-btn"
                shape="circle"
                type="primary"
                class="my-2 mx-2"
                :disabled="imageLoading || this.isAnnotated()"
                :style="{
                  backgroundColor: isHistoryApplied ? 'blue' : 'white',
                  color: isHistoryApplied ? 'white' : 'blue'
                }"
                @click="setPrevShapes"
              >
                <template #icon>
                  <HistoryOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <div class="d-flex mx-2 my-2">
              <span
                class="color-selector"
                :style="`background:${textColor}`"
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'white' }"
                @click="changeLabelColor(true)"
              />
              <span
                class="color-selector"
                :style="
                  `background:${textColor === 'black' ? 'white' : 'black'}`
                "
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'black' }"
                @click="changeLabelColor(false)"
              />
            </div>
          </a-space>
        </template>

        <template v-if="!imageLoading" #cover>
          <div
            id="deleteContextMenu"
            :style="{
              display: deleteShow ? 'initial' : 'none !important'
            }"
          >
            <button
              v-if="!isDrawingLine"
              id="delete-button"
              @click="deleteObject"
            >
              Delete
            </button>
          </div>
          <v-stage
            ref="stage"
            key="stage"
            id="mo-define-stage"
            :config="stageSize"
            @click="handleStageClick"
            @mousedown="handleStageMouseDown"
            @mousemove="handleStageMouseMove"
            @touchstart="handleStageMouseDown"
            @contextmenu="handleDeleteMenuShow"
          >
            <v-layer ref="layer">
              <v-image ref="image" :config="imageConfig" />
              <v-group
                v-for="(rect, index) in groups.rectangles"
                :key="rect.name + 1"
                :config="{ draggable: isDraggable(rect) }"
              >
                <v-rect
                  :key="rect.name"
                  :config="rect"
                  :ref="rect.name + '_ref'"
                  @transformend="handleTransform"
                />
                <v-text
                  :key="index"
                  :name="groups.rectangles[index].actual_name"
                  :config="groups.labels[index]"
                />
              </v-group>

              <v-circle v-if="circle" :config="circle" />

              <v-group
                v-for="(line, index) in polygons.lines"
                :id="'polygon-group' + '-' + index"
                :key="line.name + '-' + index"
              >
                <v-line
                  :key="line.name"
                  :name="line.name"
                  :config="{
                    points: line.points,
                    stroke: line.stroke,
                    globalCompositeOperation: 'source-over',
                    closed: line?.closed ?? flase
                  }"
                />
                <v-text
                  :id="'polygon-label-' + polygons.labels[index]?.text"
                  :key="index"
                  :name="'polygon-label-' + index"
                  :config="polygons.labels[index]"
                />
              </v-group>
              <v-transformer ref="transformer" />
            </v-layer>
            <!-- <v-layer ref="dragLayer"></v-layer> -->
          </v-stage>
        </template>

        <template #extra>
          <div class="card-actions">
            <a-button
              id="mark-object-previous-btn"
              type="primary"
              class="mr-2"
              :disabled="prevImageButtonDisabled"
              @click="changeImage(-1)"
            >
              Previous Image
            </a-button>

            <a-button
              id="mark-object-next-btn"
              type="primary"
              :disabled="nextImageButtonDisabled"
              @click="changeImage(1)"
            >
              Next Image
            </a-button>
          </div>
        </template>
      </a-card>
    </a-col>

    <a-col span="8" class="h-100 d-flex flex-column">
      <a-list
        id="mark-objects-annotate-obj-list"
        bordered
        class="objects-list"
        size="small"
        :loading="{ spinning: isObjectFetching, size: 'large' }"
        :data-source="nonStaticObjects"
        item-layout="horizontal"
      >
        <template #header>
          <span>Objects</span>
          <p v-if="showNote">
            <span class="color-box" /> Represents newly added objects. It can be
            changed.
          </p>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            :id="'mo-annotate-obj-' + index"
            :class="{
              disabled: isHandAndFaceObj(item.name, this.modelChoice),
              'selected-object':
                (alreadyLabeledObjects[item.name] &&
                  alreadyLabeledObjects[item.name].labeled === true) ||
                currentPolygonName === item.name
            }"
            class="pl-2 clickable object-list-item"
          >
            <template #actions>
              <a-space class="d-flex align-items-center">
                <div
                  v-if="
                    alreadyLabeledObjects[item.name] &&
                      alreadyLabeledObjects[item.name].labeled === true
                  "
                >
                  <input
                    type="color"
                    v-model="item.outline_color"
                    @change="handleChangeObjectColor(item)"
                    @keyup="e => handlePressEnter(e, item)"
                  />
                </div>
                <a-divider type="vertical" />
                <span>{{ this.objectsCount[item.name] }}</span>
              </a-space>
            </template>

            <a-list-item-meta @click.prevent="onObjectSelect(item)">
              <template #title>
                <span>
                  {{ item.name }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>
  </a-row>
</template>

<script>
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import { colorsConfig } from '@/utils/detector';
import {
  LoadingOutlined,
  DownOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  FontSizeOutlined,
  BorderOutlined,
  HistoryOutlined,
  HighlightOutlined
} from '@ant-design/icons-vue';
import axios from 'axios';
import * as OBJECT from '../../LabelData/ObjectAnnotation/object';
import httpClient from '../../../../../service/httpClient';
import objectsMarkingMixin from 'src/mixins/objectsMarking';
import DetectorService from 'src/services/detector.js';
import { mapActions, mapGetters } from 'vuex';
import { h } from 'vue';
import { uuid } from 'vue-uuid';
import { deepClone } from '@/utils/task';
import { isHandAndFaceObj } from '@/utils/detector';
import segmentTrackingService from 'src/services/segmentTracking';
import segmentTrackConfig from 'src/config/segment-track-config';
import { messaging, handleMessage } from 'src/utils/fcm';

export default {
  name: 'ObjectsLabellingTool',
  components: {
    DownOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FontSizeOutlined,
    BorderOutlined,
    HistoryOutlined,
    HighlightOutlined
  },

  mixins: [objectsMarkingMixin],
  inject: ['toast'],

  props: [
    'imagePathInBucket',
    'annotationFileName',
    'annotationFilePath',
    'imageId',
    'imageObj',
    'imageIndex',
    'totalResults',
    'currentPage',
    'pageSize'
  ],

  emits: [
    'changeImageAnnotationPath',
    'nextImage',
    'prevImage',
    'closeModal',
    'updateAnnotationPath',
    'updateCurrentPage'
  ],

  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '36px',
        margin: 'auto'
      },
      spin: true
    });
    return {
      indicator,
      isHandAndFaceObj
    };
  },

  data() {
    return {
      generatingImageEmbedding: null,
      alreadyLabeledObjects: {},
      imageHeight: 0,
      selectSmartSwitch: true,
      imageWidth: 0,
      disableDelete: false,
      selectedShapeIndex: -1,
      selectedShapeName: '',
      selectedShapeId: -1,
      imageToBeAnnotated: '',
      loadingSemtrack: false,
      image: null,
      imageLoading: true,
      oldObjectsId: new Set(),
      groups: {
        rectangles: {},
        labels: {}
      },
      polygons: {
        lines: {},
        labels: {}
      },
      imageDimensions: {
        width: 0,
        height: 0
      },
      stageSize: {
        width: 640,
        height: 480
      },
      is_circle_drawn: false,
      segmentTrackStatus: [],
      isSavingAnnotation: false,
      isAddingPolygon: false,
      firstPt: null,
      isDrawingLine: false,
      currentPolygonName: null,
      currentPolygonId: null,
      current_obj_id: null,
      polygon_lines: null,
      isAddingRectangle: false,
      isHistoryApplied: false,
      textColor: 'white',
      img_idx: -1,
      isObjectFetching: false,
      objectsCount: {},
      isAnythingUpdated: false,
      circle: null,
      temp_polygons: {
        lines: {},
        labels: {}
      },
      temp_groups: {
        rectangles: {},
        labels: {}
      },
      userClickInsidePolygon: false,
      deleteShow: false
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'taskObjectList',
      'taskObjects',
      'selectedTask',
      'prevGroups',
      'prevPolygons',
      'modelChoice',
      'imagesForAnnotation',
      'prevPageLastImageObj'
    ]),

    nextImageButtonDisabled() {
      const currentPageIndex =
        (this.currentPage - 1) * this.pageSize + (this.imageIndex + 1);
      return currentPageIndex >= this.totalResults;
    },
    disableSegmentTrack() {
      let isDisable =
        this.loadingSemtrack ||
        (Object.keys(this.groups.labels).length == 0 &&
          Object.keys(this.polygons.labels).length == 0);
      if (Object.keys(this.groups.labels).length == 0) isDisable = true;
      return isDisable;
    },

    prevImageButtonDisabled() {
      const currentPageIndex =
        (this.currentPage - 1) * this.pageSize + (this.imageIndex + 1);
      return currentPageIndex <= 1;
    },

    nonStaticObjects() {
      return this.taskObjects
        .filter(obj => !obj.is_static)
        .sort()
        .map((o, index) => {
          const labelObj = this.alreadyLabeledObjects[o.name];
          if (!labelObj)
            return {
              ...o,
              outline_color: o.outline_color
                ? o.outline_color
                : colorsConfig[index]
            };
          else {
            const { key, objType } = labelObj;
            let color = '';
            if (objType === 'rect') {
              color = this.groups.rectangles[key].stroke;
            } else if (objType === 'line') {
              color = this.polygons.lines[key].stroke;
            }
            return { ...o, outline_color: color };
          }
        });
    },

    showNote() {
      return this.nonStaticObjects?.some(
        obj => obj.outline_color === '#ff0000'
      );
    },

    imageConfig() {
      return {
        image: this.image,
        name: 'currentImage',
        ...this.stageSize
      };
    }
  },

  watch: {
    async imageObj(value) {
      if (!value) return;
      this.imageLoading = true;
      const res = await this.loadImage(value);
      if (!res) {
        this.imageLoading = false;
        return;
      }
      await this.renderExistingObjectList();
      await this.setPreviousFrameObjects();
      this.imageLoading = false;
    },

    textColor(newColor) {
      const { labels } = this.groups;
      const newLabels = Object.entries(labels).reduce((res, [key, value]) => {
        res[key] = { ...value, color: newColor, fill: newColor };
        return res;
      }, {});
      const { labels: polygon_labels } = this.polygons;
      const newPolygonLabels = Object.entries(polygon_labels).reduce(
        (res, [key, value]) => {
          res[key] = { ...value, color: newColor, fill: newColor };
          return res;
        },
        {}
      );
      this.groups['labels'] = newLabels;
      this.polygons['labels'] = newPolygonLabels;
    }
  },

  async created() {
    this.isObjectFetching = true;
    this.imageLoading = true;
    this.initializeAlreadyLabeledObjects();
    const res = await this.loadImage(this.imageObj);
    if (!res) {
      this.imageLoading = false;
      this.isObjectFetching = false;
      return;
    }
    this.imageLoading = false;
    this.img_idx = this.imageIndex;
    await this.updateImageConfig();
    await this.renderExistingObjectList();
    await this.updateStats();
    await this.setPreviousFrameObjects();
    this.isObjectFetching = false;
    handleMessage(messaging, this.responseOfSegmentTrack);
  },

  methods: {
    ...mapActions([
      'setTaskObjectList',
      'setPrevGroups',
      'setPrevPolygons',
      'getTaskObjects',
      'updateTaskObject'
    ]),

    dragBoundFunc(pos) {
      const container = this.$refs.stage;
      const containerWidthHalf = container.oldProps.width / 2;
      const containerHeightHalf = container.oldProps.height / 2;
      const margin = 10;

      let valx = Math.min(
        Math.max(pos.x, -containerWidthHalf + margin),
        containerWidthHalf - margin
      );
      let valy = Math.min(
        Math.max(pos.y, -containerHeightHalf + margin),
        containerHeightHalf - margin
      );

      return {
        x: valx,
        y: valy
      };
    },

    disableWebMenu(e) {
      e.evt?.preventDefault();
      return false;
    },

    async initializeSegTrack() {
      this.generatingImageEmbedding = 'Proccessing';
      await segmentTrackingService.initializeSegmentTrack(this.imageId);
    },
    initializeAlreadyLabeledObjects() {
      let objects = this.nonStaticObjects;
      objects.forEach(obj => {
        this.alreadyLabeledObjects[obj.name] = false;
      });
    },

    async updateStats() {
      await this.getTaskObjects(this.selectedTask);
      this.taskObjects.forEach(obj => {
        this.objectsCount[obj.name] = obj.number_of_examples;
      });
    },

    updateImageConfig() {
      return new Promise(async resolve => {
        const canvas = await this.getCanvasElement();
        const { width, height } = canvas.getBoundingClientRect();
        this.imageDimensions = {
          width,
          height
        };
        this.stageSize = {
          width,
          height
        };
        resolve();
      });
    },

    async setPreviousFrameObjects() {
      // if on Oth index
      if (this.prevImageButtonDisabled) {
        return;
      }
      // get previous image object
      const prevObj = this.getPreviousFrameObject();
      if (!prevObj.path_to_annotations) {
        // set prevstate
        this.setPrevPolygons(null);
        this.setPrevGroups(null);
        return;
      }
      const { polygonObjects, rectObjects } = await this.getObjectListFromS3(
        prevObj.path_to_annotations
      );
      this.setPrevGroups(rectObjects);
      this.setPrevPolygons(polygonObjects);
    },

    getPreviousFrameObject() {
      let prevImageObject = null;
      // if 1st image and page is greater than 1
      if (this.imageIndex === 0 && this.currentPage > 1) {
        prevImageObject = this.prevPageLastImageObj;
      } else {
        const prevImagIndex = this.imageIndex - 1;
        prevImageObject = this.imagesForAnnotation[prevImagIndex];
      }
      return prevImageObject;
    },

    reloadLabelFileAfterSegmentTrackApplied() {
      const { file_path } = this.getAnnotationNamePath();
      this.$emit(
        'updateAnnotationPath',
        this.getAnnotationPathForState(file_path)
      );
      this.resetInitState();
      this.renderExistingObjectList().then(() => {
        this.loadingSemtrack = false;
        this.isAddingRectangle = false;
        this.toast.success('Segment track applied.');
      });
    },
    generateImageEmbedding() {
      this.isAddingRectangle = true;
      this.generatingImageEmbedding = 'Processing';
      // generate embeddings
      segmentTrackingService.generateImageEmbadding(this.imageId).then(() => {
        this.isAddingRectangle = false;
      });
    },
    responseOfSegmentTrack(payload) {
      const { type, status, data, message } = payload.data;
      if (type !== segmentTrackConfig.message_type) return;
      const response_data = JSON.parse(data);
      console.log('Segment & Track Message', payload);
      if (response_data.path_to_annotations == this.annotationFilePath) {
        if (
          status == segmentTrackConfig.ok_200 &&
          (message === segmentTrackConfig.embeddings_generated ||
            response_data.next_action === segmentTrackConfig.auto_annotate)
        ) {
          this.generatingImageEmbedding = null;
        } else {
          this.toast.error('Segment track failed.');
          this.loadingSemtrack = false;
          this.isAddingRectangle = false;
        }
      }
    },
    convertRectangleToPolygon(rectangle) {
      const [xmin, ymin] = [rectangle.x, rectangle.y];
      const [xmax, ymax] = [xmin + rectangle.width, ymin + rectangle.height];
      return [
        [
          Math.round(xmin * 640),
          Math.round(ymin * 480),
          Math.round(xmax * 640),
          Math.round(ymax * 480)
        ]
      ];
    },
    convertPolygonToRectangle(rectangle, index, polygon) {
      return {
        points: polygon[index],
        name: rectangle.actual_name,
        stroke: rectangle.stroke,
        actual_id: rectangle.actual_id
      };
    },
    async segmentTracking() {
      this.loadingSemtrack = true;
      const annotations = this.getAnnotations();
      const polygon = Object.values(annotations).map(
        this.convertRectangleToPolygon
      );
      const [
        error,
        response
      ] = await segmentTrackingService.calculateSegmentTrack(this.imageId, {
        bbox: polygon
      });
      if (error == null) {
        const existingObj = Object.values(
          this.groups.rectangles
        ).map((rec, i) =>
          this.convertPolygonToRectangle(rec, i, response.bbox)
        );
        this.initializeAlreadyLabeledObjects();
        this.groups = { rectangles: {}, labels: {} };
        this.updatePolygons(existingObj);
        this.calculateAlreadyLabeledObjects();
        this.isAnythingUpdated = true;
      } else {
        this.toast.error('Please try again, embedding not found!');
        this.generateImageEmbedding();
      }

      this.loadingSemtrack = false;
    },
    setPrevShapes() {
      if (this.isHistoryApplied == false) {
        const currRects = this.groups.rectangles;
        const currPolygons = this.polygons.lines;
        if (
          this.isAnnotated() &&
          (Object.keys(currRects)?.length || Object.keys(currPolygons).length)
        ) {
          this.toast.error('Image is already annotated!');
          return;
        }
        if (!this.prevGroups?.length && !this.prevPolygons?.length) {
          this.toast.error("There's no objects on previous image!");
          return;
        }
        this.resetInitState();
        this.updateGroups(this.prevGroups);
        this.updatePolygons(this.prevPolygons);
        this.calculateAlreadyLabeledObjects();
        this.setOldObjectsID();
        this.isHistoryApplied = true;
        this.isAnythingUpdated = true;
      }
    },

    getCanvasElement() {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          const canvas = document.getElementsByTagName('canvas')[0];
          if (canvas) {
            clearInterval(interval);
            resolve(canvas);
          }
        }, 1000);
      });
    },

    isDraggable(object) {
      return OBJECT.isDraggable(object);
    },

    isAnnotated() {
      return this.annotationFilePath !== null;
    },

    removePreviousPolygonAnnotation() {
      // if polygon select to draw but leave incomplete
      // and select other object to draw
      if (
        this.isAddingPolygon &&
        this.polygons.lines[this.current_obj_id] &&
        !this.polygons.labels[this.current_obj_id]?.name
      ) {
        this.alreadyLabeledObjects[
          this.polygons.lines[this.current_obj_id]['name']
        ] = false;
        delete this.polygons.lines[this.current_obj_id];
        delete this.polygons.labels[this.current_obj_id];
      }
    },

    onObjectSelect(object) {
      const objName = object.name;
      if (this.alreadyLabeledObjects[objName]) {
        this.toast.error('This object is already labeled!');
        return;
      }
      this.removePreviousPolygonAnnotation();
      this.currentPolygonName =
        this.currentPolygonName === objName ? null : objName;
      this.currentPolygonId =
        this.currentPolygoId === object.id ? null : object.id;
      this.isAddingPolygon = false;
      this.isAddingRectangle = false;
    },

    // add new object in groups
    setNewObjectConfigToDraw() {
      if (this.isAddingRectangle) return;
      if (!this.currentPolygonName) {
        this.toast.error('Please Select Object from list');
        return;
      }
      const object = {
        cordinates_of_static_object: null,
        is_static: false,
        name: this.currentPolygonName,
        actual_id: this.currentPolygonId,
        id: uuid.v4()
      };

      let { rectangle, label } = this.createRectLabelGroup(object);
      const newGroups = this.groups;
      newGroups.rectangles[rectangle.name] = {
        ...rectangle,
        stroke: '#ff0000'
      };
      newGroups.labels[rectangle.name] = { ...label };
      this.isAddingRectangle = true;
      this.isAnythingUpdated = true;
      this.isAddingPolygon = false;
      this.calculateAlreadyLabeledObjects();
    },

    // get Image to Display
    async getImageUrlFromS3() {
      if (!this.imagePathInBucket) return;
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: this.imagePathInBucket
      };
      const { presigned_url } = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      return presigned_url;
    },

    loadImage(imageObj) {
      return new Promise(async (resolve, reject) => {
        let imageSource = '';
        const img = new window.Image();
        if (!imageObj.url) imageSource = await this.getImageUrlFromS3();
        else imageSource = imageObj.url;
        img.src = imageSource;
        img.onload = () => {
          this.image = img;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;
          resolve(imageSource);
        };
        img.onerror = () => {
          resolve(0);
        };
      });
    },

    // for rendering existing objects
    getObjectListFromState() {
      return this.taskObjectList.map(o => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          )
        };
      });
    },

    async getObjectListFromS3(objectPath) {
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: objectPath
      };
      const res = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      let { data } = await axios.get(res.presigned_url);
      const labeledAnnotations = data.filter(o =>
        this.nonStaticObjects.some(ns => ns.id === o.actual_id)
      );
      const polygonObjects = labeledAnnotations?.filter(o =>
        o.hasOwnProperty('points')
      );
      const rectObjects = labeledAnnotations
        ?.filter(o => !o.hasOwnProperty('points'))
        ?.map(OBJECT.nestCoordinates);

      return {
        polygonObjects: polygonObjects ? polygonObjects : [],
        rectObjects: rectObjects ? rectObjects : []
      };
    },

    renderExistingObjectList() {
      return new Promise(async resolve => {
        if (!this.isAnnotated()) {
          resolve();
          return;
        }
        const { polygonObjects, rectObjects } = await this.getObjectListFromS3(
          this.annotationFilePath
        );
        this.updateGroups(rectObjects);
        this.updatePolygons(polygonObjects);
        this.temp_polygons = deepClone(this.polygons);
        this.temp_groups = deepClone(this.groups);

        this.calculateAlreadyLabeledObjects();
        this.setOldObjectsID();
        resolve();
      });
    },

    updateGroups(rectObjects) {
      if (!rectObjects.length) return;
      const s3Objects = rectObjects?.filter(
        o => !o.is_static && !o.hasOwnProperty('points')
      );
      const existingGroups = {
        ...this.createRectanglesFromExistingObjects([...s3Objects])
      };
      this.setObjectColor(existingGroups.rectangles);
      this.groups = existingGroups;
    },

    updatePolygons(polygonObjects) {
      if (!polygonObjects.length) return;
      const existingPolygons = this.createPolygonsFromExistingObjects(
        polygonObjects
      );
      this.setObjectColor(existingPolygons.lines);
      this.polygons = {
        labels: { ...this.polygons.labels, ...existingPolygons.labels },
        lines: { ...this.polygons.lines, ...existingPolygons.lines }
      };
    },

    calculateAlreadyLabeledObjects() {
      const tempLabelObjects = this.alreadyLabeledObjects;
      for (const key of Object.keys(this.polygons.lines)) {
        tempLabelObjects[this.polygons.lines[key]['name']] = {
          key: key,
          labeled: true,
          objType: 'line'
        };
      }
      for (const key of Object.keys(this.groups.rectangles)) {
        tempLabelObjects[this.groups.rectangles[key]['actual_name']] = {
          key: key,
          labeled: true,
          objType: 'rect'
        };
      }
    },

    setOldObjectsID() {
      this.nonStaticObjects
        .filter(o => this.alreadyLabeledObjects[o.name].labeled === true)
        .forEach(o => this.oldObjectsId.add(o.id));
    },

    setObjectColor(objects) {
      let objName = '';
      Object.values(objects).forEach(o => {
        objName = o.actual_name ? o.actual_name : o.name;
        o['stroke'] = this.nonStaticObjects.find(
          ns => ns.name == objName
        )?.outline_color;
      });
    },

    uploadAnnotationToS3(s3Objects, hidetoast) {
      return new Promise(async resolve => {
        const { file_path, file_name } = this.getAnnotationNamePath();
        const payload = this.getPayloadForUploading(
          s3Objects,
          file_path,
          file_name
        );

        const fileUploadResp = DetectorService.uploadObjectFile(
          this.imageId,
          payload,
          false
        );
        const imageAnnotationResp = DetectorService.updateImageAnnotation(
          { path_to_annotations: file_path },
          this.imageId,
          false
        );

        this.$emit(
          'updateAnnotationPath',
          this.getAnnotationPathForState(file_path)
        );

        const [[error], [_error, data]] = await Promise.all([
          fileUploadResp,
          imageAnnotationResp
        ]);

        if (!error && !_error) {
          if (hidetoast != true) this.toast.success('Annotations Saved', 3000);
        } else console.log({ error, _error });
        resolve();
      });
    },

    getAnnotationPathForState(file_path) {
      const rects = Object.keys(this.groups.rectangles);
      const polygons = Object.keys(this.polygons.lines);
      if (!rects.length && !polygons.length) return null;
      else return file_path;
    },

    getAnnotationNamePath() {
      if (this.annotationFilePath)
        return {
          file_path: this.annotationFilePath,
          file_name: this.annotationFileName
        };

      const pathSplit = this.imagePathInBucket.split('/');
      const imageFileName = pathSplit.at(-1);
      return {
        file_path: `${pathSplit
          .slice(0, 2)
          .join('/')}/labels/${imageFileName}.json`,
        file_name: `${imageFileName}.json`
      };
    },

    getPayloadForUploading(s3Objects, file_path, file_name) {
      const fileAsJSON = JSON.stringify(s3Objects);
      const blob = new Blob([fileAsJSON], { type: 'application/json' });
      const data = new FormData();
      data.append('file', blob, file_name);
      data.append('file_path', file_path);
      data.append('bucket', this.organization + '-training');
      return data;
    },

    changeLabelColor(isActive) {
      if (isActive) return;
      if (this.textColor === 'white') this.textColor = 'black';
      else this.textColor = 'white';
    },

    // updateAllCoordinates(staticObjs) {
    //   const objectsTobeUpdated = staticObjs
    //     .filter((obj) => this.updatedStaticObjectId.has(obj.id))
    //     .map((obj) => ({
    //       ...obj,
    //       cordinates_of_static_object: JSON.stringify(
    //         obj.cordinates_of_static_object
    //       ),
    //     }));
    //   DetectorService.updateObjectsCoordinates(objectsTobeUpdated, false);
    // },

    async saveAnnotation(hidetoast = false) {
      if (this.isAddingPolygon) {
        // delete this.polygons.lines[this.current_obj_id];
        this.isDrawingLine = false;
        this.firstPt = null;
        this.isAddingPolygon = false;
        this.currentPolygonName = null;
        this.currentPolygonId = null;
        this.current_obj_id = null;
      }

      if (!this.shouldUpdateFile()) {
        return;
      }

      this.isSavingAnnotation = true;
      let newAnnotations = this.getAnnotations();
      const polygonsList = this.getPolygonsAnnotations();
      newAnnotations = newAnnotations.concat(polygonsList);

      await this.uploadAnnotationToS3(newAnnotations, hidetoast);
      this.isAnythingUpdated = false;
      this.isSavingAnnotation = false;
    },

    async deleteObject() {
      if (
        this.selectedShapeName &&
        this.groups.rectangles[this.selectedShapeName]
      ) {
        this.alreadyLabeledObjects[
          this.groups.labels[this.selectedShapeName]['text']
        ] = false;
        delete this.groups.rectangles[this.selectedShapeName];
        delete this.groups.labels[this.selectedShapeName];
        this.selectedShapeName = null;
        this.isAnythingUpdated = true;
        this.isAddingRectangle = false;
      } else if (
        this.current_obj_id &&
        this.polygons.lines[this.current_obj_id]
      ) {
        this.alreadyLabeledObjects[
          this.polygons.labels[this.current_obj_id]['text']
        ] = false;
        delete this.polygons.lines[this.current_obj_id];
        delete this.polygons.labels[this.current_obj_id];
        this.current_obj_id = null;
        this.isAnythingUpdated = true;
        this.firstPt = null;
        this.isAddingPolygon = false;
      }
      this.updateTransformer();
      this.deleteShow = false;
    },

    deleteCurrentStartingCirclePoint() {
      if (this.is_circle_drawn && this.circle) {
        this.circle = null;
        this.is_circle_drawn = false;
      }
    },

    // closeCurrentPolygonIfNotClosed() {
    //   if (this.firstPt && this.current_obj_id && this.isAddingPolygon) {
    //     const currLine = this.polygons.lines[this.current_obj_id];
    //     currLine.points = currLine.points.concat(this.firstPt);
    //     this.deleteCurrentStartingCirclePoint();
    //     this.disableDelete = false;
    //   }
    // },

    drawPolygon() {
      if (!this.polygons.lines.hasOwnProperty(this.current_obj_id)) {
        return;
      }

      var pos = this.$refs.stage.getStage().getPointerPosition();
      pos.x = (pos.x / this.imageDimensions.width) * this.stageSize.width;
      pos.y = (pos.y / this.imageDimensions.height) * this.stageSize.height;
      const pt = [pos.x, pos.y];

      if (!this.isDrawingLine) {
        this.isDrawingLine = true;
        this.firstPt = pt;
        this.idx++;
        const currLine = this.polygons.lines[this.current_obj_id];
        currLine.points = currLine.points.concat(pt);
        pt[0] += 2;
        currLine.points = currLine.points.concat(pt);
        this.is_circle_drawn = true;
        this.circle = {
          x: pt[0],
          y: pt[1],
          radius: 3,
          fill: currLine.stroke,
          stroke: currLine.stroke,
          strokeWidth: 2
        };
      } else if (this.handleShapeIntersection(pt)) {
        let unique_points_in_polygon = [];
        this.polygons.lines[this.current_obj_id].points.forEach(point => {
          unique_points_in_polygon.push(Math.round(point));
        });
        unique_points_in_polygon = [...new Set(unique_points_in_polygon)];
        if (unique_points_in_polygon.length < 7) {
          this.toast.error('polygon should have atleast 3 proper points!');
          return;
        }
        this.isDrawingLine = false;
        const currLine = this.polygons.lines[this.current_obj_id];
        currLine['closed'] = true;
        // currLine.points = currLine.points.concat(pt);
        this.firstPt = null;
        this.isAddingPolygon = false;
        this.handleAddObject();
        this.isAnythingUpdated = true;
        this.deleteCurrentStartingCirclePoint();
        this.disableDelete = false;
      } else {
        this.polygons.lines[this.current_obj_id].points = this.polygons.lines[
          this.current_obj_id
        ].points.concat(pt);
      }
    },

    isClickInsidePolygon() {
      let isPointInsideArea = false;
      const pos = this.$refs.stage.getStage().getPointerPosition();

      for (var key in this.polygons.lines) {
        if (this.isPointInsidePolygon(pos, this.polygons.lines[key].points)) {
          this.current_obj_id = key;
          isPointInsideArea = true;
        }
      }
      return isPointInsideArea;
    },

    isPointInsidePolygon(point, polygon_points) {
      const x = point.x;
      const y = point.y;

      let inside = false;
      for (
        let i = 0, j = polygon_points.length - 2;
        i < polygon_points.length;

      ) {
        const xi = polygon_points[i],
          yi = polygon_points[i + 1];
        const xj = polygon_points[j],
          yj = polygon_points[j + 1];
        const intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
        j = i;
        i += 2;
      }
      return inside;
    },

    handleDeleteMenuShow(e) {
      e.evt?.preventDefault();
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      const pos = this.$refs.stage.getStage().getPointerPosition();
      const name = e.target?.name();
      const stage = document.getElementById('mo-define-stage');
      if ((name && name !== 'currentImage') || this.isClickInsidePolygon()) {
        const { top, left } = stage.getBoundingClientRect();
        deleteContextMenu.style.top = `${pos.y + 10}px`;
        deleteContextMenu.style.left = `${pos.x - 50}px`;
        this.deleteShow = true;
        const rect = this.groups.rectangles[name];
        const line = Object.values(this.polygons.lines)?.find(
          line => line.name === name
        );
        if (rect) this.selectedShapeName = name;
        else if (line) this.current_obj_id = line.id;
      }
    },

    handleStageClick(e) {
      e.evt?.preventDefault();
      if (e.evt?.button === 2) return;
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      if (deleteContextMenu.style.display === 'initial') {
        this.selectedShapeName = null;
        this.current_obj_id = null;
        this.deleteShow = false;
      }
    },

    handleStageMouseMove(e) {
      if (!this.firstPt || !this.isAddingPolygon) {
        return;
      }

      if (
        this.polygons.lines.hasOwnProperty(this.current_obj_id) &&
        this.polygons.lines[this.current_obj_id].points.length
      ) {
        this.polygons.lines[this.current_obj_id].points.pop();
        this.polygons.lines[this.current_obj_id].points.pop();
      }

      var pos = this.$refs.stage.getStage().getPointerPosition();
      pos.x = (pos.x / this.imageDimensions.width) * this.stageSize.width;
      pos.y = (pos.y / this.imageDimensions.height) * this.stageSize.height;
      const pt = [pos.x, pos.y];

      this.polygons.lines[this.current_obj_id].points = this.polygons.lines[
        this.current_obj_id
      ].points.concat(pt);
    },

    // set Transformer when click on stage
    handleStageMouseDown(e) {
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }

      if (this.isAddingPolygon) {
        this.drawPolygon();
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const rect = this.groups.rectangles[name];
      const line = this.polygons.lines[name];

      if (rect) {
        this.selectedShapeName = name;
      } else if (line) {
        this.current_obj_id = name;
      } else {
        this.userClickInsidePolygon = this.isClickInsidePolygon();
        if (
          !this.userClickInsidePolygon &&
          this.currentPolygonName &&
          !(this.isAddingRectangle || this.isAddingPolygon)
        ) {
          this.toast.error('Please Select the drawing type');
        }
        this.selectedShapeName = '';
      }

      this.updateTransformer();
    },

    handleShapeIntersection(point) {
      const distance = this.calcDistance(
        this.firstPt[0],
        this.firstPt[1],
        point[0],
        point[1]
      );
      return distance <= 5;
    },

    calcDistance(x1, y1, x2, y2) {
      return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    },

    addNewPolygon() {
      this.disableDelete = true;
      if (!this.currentPolygonName) {
        this.toast.error('Please Select Object from List');
        return;
      }
      if (this.alreadyLabeledObjects[this.currentPolygonName]) {
        this.toast.error('This object is already labeled!');
        return;
      }
      this.isAddingPolygon = true;
      this.isAddingRectangle = false;
      this.setNewObjConfig();
    },

    setNewObjConfig() {
      this.current_obj_id = uuid.v4();
      this.polygons.lines[this.current_obj_id] = {
        points: [],
        id: this.current_obj_id,
        stroke: '#ff0000',
        name: this.currentPolygonName,
        actual_id: this.currentPolygonId
      };
      this.polygons.labels[this.current_obj_id] = {};
      this.calculateAlreadyLabeledObjects();
    },

    handleAddObject() {
      const { lines, labels } = this.polygons;
      const currentPolygon = lines[this.current_obj_id];
      const label = this.createLabelForPolygonConfig(
        currentPolygon.points,
        this.currentPolygonName,
        this.current_obj_id
      );
      currentPolygon.name = this.currentPolygonName;
      labels[this.current_obj_id] = label;
      this.current_obj_id = null;
      this.currentPolygonName = null;
      this.currentPolygonId = null;
    },

    async changeImage(index) {
      this.isHistoryApplied = false;
      this.loadingSemtrack = false;
      await this.saveCurrentImageAnnotation(false);
      this.resetInitState();
      this.img_idx = this.img_idx + index;
      await this.updateStats();
      if (
        this.img_idx >= this.pageSize &&
        this.currentPage < Math.ceil(this.totalResults / this.pageSize)
      ) {
        this.$emit('updateCurrentPage', this.currentPage + 1);
        return;
      }

      if (this.img_idx + 1 <= 0 && this.currentPage > 1) {
        this.$emit('updateCurrentPage', this.currentPage - 1);
        return;
      }
      this.$emit('prevImage', index);
    },

    resetInitState() {
      this.initializeAlreadyLabeledObjects();
      this.groups = { rectangles: {}, labels: {} };
      this.polygons = { lines: {}, labels: {} };
      this.polygon_lines = null;
      this.deleteShow = false;
    },

    shouldUpdateFile() {
      return (
        JSON.stringify(this.polygons.lines) !==
          JSON.stringify(this.temp_polygons.lines) ||
        JSON.stringify(this.groups) !== JSON.stringify(this.temp_groups)
      );
    },

    async saveCurrentImageAnnotation(hidetoast = false) {
      if (this.isAddingPolygon) {
        delete this.polygons.lines[this.current_obj_id];
        this.isDrawingLine = false;
        this.firstPt = null;
        this.deleteCurrentStartingCirclePoint();
      }

      this.isAddingPolygon = false;
      this.isAddingRectangle = false;
      this.currentPolygonName = null;
      this.currentPolygonId = null;
      this.current_obj_id = null;

      if (!this.shouldUpdateFile() || !this.isAnythingUpdated) {
        return;
      }
      let newAnnotations = this.getAnnotations();
      const polygonsList = this.getPolygonsAnnotations();
      newAnnotations = newAnnotations.concat(polygonsList);

      await this.uploadAnnotationToS3(newAnnotations, hidetoast);
      this.isAnythingUpdated = false;
    },

    handlePressEnter(e, object) {
      if (e.keyCode === 13) this.handleChangeObjectColor(object);
    },

    handleChangeObjectColor(object) {
      const objName = object.name;
      const { key, objType } = this.alreadyLabeledObjects[objName];
      const newColor = object.outline_color;
      if (objType === 'rect') {
        this.groups.rectangles[key].stroke = newColor;
      } else if (objType === 'line') {
        this.polygons.lines[key].stroke = newColor;
      }
      const payload = {
        objectId: object.id,
        objectData: { ...object }
      };
      this.updateTaskObject(payload);
    }
  }
};
</script>
<style>
.annotation-image-card > .ant-card-cover {
  padding: 1em !important;
  flex-grow: 1;
  display: flex !important;
  position: relative !important;
}

.annotation-image-card > .ant-card-cover > div {
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content {
  width: unset !important;
  height: unset !important;
  flex-grow: 1 !important;
  display: flex;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content > canvas {
  border-radius: 5px;
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
  border: 1.5px solid #d9d9d9 !important;
}

.color-selector {
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  border: 1.5px solid #d9d9d9;
}

.color-selector:nth-child(2) {
  transform: translate(-10px, 7px);
  z-index: 0;
}

.objects-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.objects-list > .ant-list-header {
  background: lightgray !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.objects-list > .ant-list-header span {
  font-weight: 600 !important;
}

.objects-list > .ant-list-header p {
  margin: 0;
  font-size: 11px;
}

.objects-list > .ant-spin-nested-loading {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

/* .entity-list-item:hover {
  background: rgb(231, 231, 231);
} */

.selected-object {
  background: rgb(241, 238, 238);
}

.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
  background: red;
}
</style>
