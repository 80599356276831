import ApiHelper from './index';

async function generateSegmentTrack(taskId, objectId, spinner = true) {
  return await ApiHelper.get(
    `organization/task/seg_and_track_annotate/${taskId}/${objectId}/`,
    spinner
  );
}

async function initializeSegmentTrack(imageId, spinner = true) {
  return await ApiHelper.get(
    `organization/task/auto_annotate/annotate_image/${imageId}/?action=load_embeddings`,
    spinner
  );
}

async function generateImageEmbadding(imageId, spinner = true) {
  return await ApiHelper.get(
    `organization/task/generate_embedding/annotate_image/${imageId}/`,
    spinner
  );
}

async function calculateSegmentTrack(imageId, payload, spinner = true) {
  return await ApiHelper.post(
    `organization/task/auto_annotate/annotate_image/${imageId}/`,
    payload,
    spinner
  );
}

export default {
  generateSegmentTrack,
  initializeSegmentTrack,
  generateImageEmbadding,
  calculateSegmentTrack
};
