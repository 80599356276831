<template>
  <div class="h-100 d-flex flex-column">
    <a-card
      id="mark-objects-card"
      class="h-100 d-flex flex-column"
      size="small"
      :body-style="cardBodyStyle"
      :loading="loadingImages"
    >
      <DrawObjects
        v-if="selectedImage"
        ref="drawObjects"
        :image-path-in-bucket="requestedImagePath"
        :annotation-file-name="requestImageAnnotationName"
        :annotation-file-path="requestImageAnnotationPath"
        :image-id="requestedImageId"
        :image-obj="selectedImage"
        :image-index="currentImageIndex"
        :total-results="totalImagesForAnnotation"
        :current-page="currentPageForImage"
        :page-size="pageSizeForImage"
        @closeModal="selectedImage = null"
        @nextImage="handleImageChange"
        @prevImage="handleImageChange"
        @updateCurrentPage="handleCurrentPageChange"
        @updateAnnotationPath="updateAnnotationPath"
      />

      <template #title>
        <div class="d-flex align-items-center">
          <a-button
            v-if="selectedImage"
            class="text-dark"
            type="link"
            @click="selectedImage = null"
          >
            <template #icon>
              <arrow-left-outlined />
            </template>
          </a-button>
          <a-typography-title v-if="!selectedImage" :level="5" class="my-0">
            Total Images: &nbsp;
            <a-tag id="annotation-objects-count" color="blue">
              {{ totalImagesForAnnotation }}
            </a-tag>
          </a-typography-title>
          <a-typography-title v-else :level="5" class="mx-auto">
            {{ getFileName(selectedImage.path_to_image) }}
          </a-typography-title>
        </div>
      </template>

      <template v-if="!selectedImage" #extra>
        <a-space>
          <a-tooltip title="Object Count Statistics">
            <a-button
              id="images-stats-btn"
              type="default"
              size="small"
              class="mr-2"
              @click="visible = true"
            >
              <template #icon>
                <BarChartOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip title="Reload Images">
            <a-button
              id="refresh-images-btn"
              type="default"
              size="small"
              @click="reloadImages"
            >
              <template #icon>
                <reload-outlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <template #actions>
        <a-pagination
          v-if="!selectedImage"
          id="object-detection-pagination"
          :total="totalImagesForAnnotation"
          v-model:current="currentPageForImage"
          v-model:pageSize="pageSizeForImage"
          show-size-changer
          :show-total="
            (total, range) => `${range[0]}-${range[1]} of ${total} Frames`
          "
          class="w-100 d-flex justify-content-center"
          :page-size-options="[30, 50, 100, 150]"
          @change="handlePageChange"
        />

        <a-space v-else style="float: right; margin-right: 5em">
          <a-button class="ml-auto mr-2" @click="selectedImage = null">
            Back to Images List
          </a-button>
          <a-button
            id="mo-save-annotation-btn"
            type="primary"
            :loading="$refs.drawObjects?.isSavingAnnotation"
            @click="$refs.drawObjects?.saveAnnotation"
          >
            Save
          </a-button>
        </a-space>
      </template>

      <a-list
        v-if="!selectedImage"
        :grid="{
          gutter: 32,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
          xxxl: 5
        }"
        :data-source="imagesForAnnotation"
        id="label-objects-list"
      >
        <template #renderItem="{ item, index }">
          <a-list-item class="objects-list-item">
            <a-badge-ribbon
              v-if="item.path_to_annotations"
              color="#87d068"
              text="Annotated"
            >
              <preview-image-card
                :imageObj="item"
                :index="index"
                :get-file-name="getFileName"
                :open-annotation-modal="openAnnotationModal"
                :delete-annotation="deleteAnnotation"
                @updateImageObject="updateImageObjectInList"
              />
            </a-badge-ribbon>
            <preview-image-card
              v-else
              :imageObj="item"
              :index="index"
              :get-file-name="getFileName"
              :open-annotation-modal="openAnnotationModal"
              :delete-annotation="deleteAnnotation"
              @updateImageObject="updateImageObjectInList"
            />
          </a-list-item>
        </template>
      </a-list>
    </a-card>
  </div>
  <a-modal
    :visible="visible"
    title="Total Labeled Objects"
    :footer="null"
    :body-style="{ maxHeight: '400px', overflow: 'auto' }"
    @cancel="visible = false"
    class="p-1 pb-3"
  >
    <a-list
      size="small"
      :data-source="[...annotatedObjectList]"
      :loading="annotatedObjectList.length == 0"
    >
      <template #renderItem="{ item }">
        <a-list-item v-if="!item.is_static">
          <div>
            {{ item.name }}
          </div>
          <div>
            {{ item.count }}
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>
<script>
import {
  ReloadOutlined,
  ArrowLeftOutlined,
  BarChartOutlined,
  AppstoreOutlined
} from '@ant-design/icons-vue';
import { mapGetters, mapActions } from 'vuex';
import DrawObjects from './DrawObjects.vue';
import httpClient from '../../../../../service/httpClient';
import PreviewImageCard from './PreviewImageCard.vue';
import {
  setupFCMforSegmentTrack,
  teardownFCMSegmentTrack
} from 'src/utils/fcm';

export default {
  name: 'AnnotationList',
  components: {
    ReloadOutlined,
    AppstoreOutlined,
    ArrowLeftOutlined,
    BarChartOutlined,
    DrawObjects,
    PreviewImageCard
  },

  data() {
    return {
      annotatedObjectList: [],
      visible: false,
      openObjectAnnotateDialog: false,
      requestedImagePath: '',
      requestedImageId: '',
      requestImageAnnotationPath: '',
      requestImageAnnotationName: '',
      user: localStorage.getItem('id'),
      currentPageForImage: 1,
      pageSizeForImage: 30,
      currentImageIndex: -1,
      selectedImage: null,
      img_idx: null
    };
  },

  watch: {
    visible: async function() {
      await this.getAnnotatedObjectCount();
    }
  },

  computed: {
    ...mapGetters([
      'organization',
      'taskName',
      'selectedTask',
      'taskObjects',
      'taskRegionMarkingImgUrl',
      'loadingImages',
      'imagesForAnnotation',
      'totalImagesForAnnotation',
      ,
    ]),

    cardBodyStyle() {
      return !this.selectedImage
        ? {
            flexGrow: '1',
            height: '1px',
            overflowY: 'auto',
            overflowX: 'hidden'
          }
        : { height: '100%' };
    }
  },

  async created() {
    await this.SetupFirebaseNotification();
  },

  async mounted() {
    await this.handleGetImagesForAnnotation();
    await this.getAnnotatedObjectCount();
  },

  unmounted() {
    this.resetAnnotationImagesList();
    teardownFCMSegmentTrack();
  },

  methods: {
    ...mapActions([
      'getTaskObjects',
      'loadImagesForAnnotation',
      'setAnnotationImagesList',
      'setListLastImageObject',
      'resetAnnotationImagesList'
    ]),

    async SetupFirebaseNotification() {
      const permission = Notification.permission;
      console.log(permission);
      if (localStorage.getItem('fcmToken')) {
        localStorage.removeItem('fcmToken');
      }
      if (permission === 'granted') {
        await setupFCMforSegmentTrack();
      }
    },

    currentImageIndexChanged(index) {
      if (index < 0 || index >= this.totalImagesForAnnotation) return;
      console.log('current image index change ->', index);
      this.selectedImage = this.imagesForAnnotation[index];
      this.openImageForAnnotation(this.imagesForAnnotation[index]);
    },

    async getAnnotatedObjectCount() {
      this.getTaskObjects(this.selectedTask).then(_ => {
        this.annotatedObjectList = [];
        this.taskObjects.forEach(obj => {
          console.log({ name: obj.name }, { count: obj.number_of_examples });
          this.annotatedObjectList.push({
            name: obj.name,
            count: obj.number_of_examples,
            is_static: obj.is_static
          });
        });
      });
    },

    async handleGetImagesForAnnotation() {
      const payload = {
        task: this.selectedTask,
        page: this.currentPageForImage,
        pageSize: this.pageSizeForImage,
        ordering: 'id'
      };
      await this.loadImagesForAnnotation(payload);
    },

    async deleteImageAnnotation(id) {
      const res = await httpClient.delete(
        `organization/task/annotation_image/`,
        id + '/',
        false
      );
      if (res === 'error') {
        this.toast.error('error in getting images');
        return;
      }
      await this.handleGetImagesForAnnotation();
    },

    async reloadImages() {
      this.handleGetImagesForAnnotation();
    },

    handleImageChange(newIndex) {
      this.currentImageIndex += newIndex;
      this.currentImageIndexChanged(this.currentImageIndex);
    },

    async handleCurrentPageChange(newPage) {
      if (newPage) {
        if (this.currentPageForImage > newPage) {
          this.currentImageIndex = this.pageSizeForImage - 1;
        }
        if (this.currentPageForImage < newPage) {
          this.currentImageIndex = 0;
        }
        this.setListLastImageObject();
        this.currentPageForImage = newPage;
        await this.handleGetImagesForAnnotation();
        this.currentImageIndexChanged(this.currentImageIndex);
      }
    },

    openAnnotationModal(index) {
      this.selectedImage = this.imagesForAnnotation[index];
      this.currentImageIndex = index;
      this.currentImageIndexChanged(this.currentImageIndex);
      // this.openImageForAnnotation(this.selectedImage);
    },

    setImageData(image) {
      if (!image) return;
      this.requestedImagePath = image.path_to_image;
      this.requestedImageId = image.id;
      this.requestImageAnnotationName = this.getFileName(
        image.path_to_annotations
      );
      this.requestImageAnnotationPath = image.path_to_annotations;
    },

    async deleteAnnotation(index, item) {
      await this.deleteImageAnnotation(item['id']);
    },

    async openImageForAnnotation(image) {
      this.setImageData(image);
      this.openObjectAnnotateDialog = true;
      this.startTime = new Date().toISOString();
    },

    updateAnnotationPath(updatedAnnotationPath) {
      const temp_imgs = [...this.imagesForAnnotation];
      temp_imgs[
        this.currentImageIndex
      ].path_to_annotations = updatedAnnotationPath;
      this.requestImageAnnotationPath = updatedAnnotationPath;

      this.setAnnotationImagesList(temp_imgs);
    },

    getFileName(name) {
      if (name) return name.split('/').at(-1);
    },

    handlePageChange(page) {
      this.setListLastImageObject();
      this.handleGetImagesForAnnotation();
    },

    updateImageObjectInList(index, data) {
      const temp_imgs = [...this.imagesForAnnotation];
      temp_imgs[index]['url'] = data;
      this.setAnnotationImagesList(temp_imgs);
    }
  }
};
</script>

<style scoped>
#components-grid-demo-playground pre {
  background: #f9f9f9;
  border-radius: 6px;
  font-size: 13px;
  padding: 8px 16px;
}
</style>
<style>
#object-detection-pagination
  > li:last-child
  .ant-pagination-options-size-changer.ant-select {
  width: 90px !important;
}
.grid-icon-btn {
  border-color: #d9d9d9;
}
.grid-icon-btn svg {
  width: 14px;
  height: 14px;
}
#label-objects-list .ant-list-item {
  display: flex;
  justify-content: center;
}
</style>
