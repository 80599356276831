<template>
  <a-card
    :id="'object-image-' + index"
    size="small"
    hoverable
    class="image-card"
    style="width: 300px"
  >
    <a-card-meta>
      <template #title>
        <div class="w-100 d-flex" style="font-weight: 400 !important">
          {{ getFileName(imageObj.path_to_image) }}
        </div>
      </template>
    </a-card-meta>

    <template #cover>
      <div class="w-100 d-flex" style="height: 200px" v-if="urlLoading">
        <a-spin class="m-auto" tip="Loading Image..." />
      </div>
      <img
        alt=""
        :src="imageObj.url ? imageObj.url : null"
        style="width: 100%"
      />
    </template>

    <template #actions>
      <div class="d-flex justify-content-center">
        <a-tooltip title="Annotate">
          <a-button
            type="default"
            :id="'video-img-edit-btn-' + index"
            :class="{ disabled: urlLoading }"
            style="flex: 1; border: none; box-shadow: none"
            @click="openAnnotationModal(index, imageObj)"
          >
            <template #icon>
              <edit-outlined class="text-primary" />
            </template>
          </a-button>
        </a-tooltip>
        <a-divider type="vertical" style="height: 30px" />
        <a-popconfirm
          cancel-text="No"
          ok-text="Yes"
          title="Are you sure you want to delete this image?"
          @confirm="deleteAnnotation(index, imageObj)"
        >
          <a-button
            type="default"
            :id="'video-img-delete-btn-' + index"
            :class="{ disabled: urlLoading }"
            style="flex: 1; border: none; box-shadow: none"
          >
            <template #icon>
              <delete-outlined class="text-danger" />
            </template>
          </a-button>
        </a-popconfirm>
      </div>
    </template>
  </a-card>
</template>
<script>
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { mapGetters } from 'vuex';
import VideoService from 'src/services/videos';
export default {
  components: {
    EditOutlined,
    DeleteOutlined,
  },
  props: {
    index: Number,
    imageObj: Object,
    getFileName: Function,
    openAnnotationModal: Function,
    deleteAnnotation: Function,
  },
  emits: ['updateImageObject'],
  mounted() {
    if (!this.imageObj?.url) this.appendUrlInImageObject();
  },
  computed: {
    ...mapGetters(['organization']),
    urlLoading() {
      return this.imageObj?.url ? false : true;
    },
  },
  methods: {
    async appendUrlInImageObject() {
      const tempObj = this.imageObj;
      if (!tempObj.path_to_image) return;
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: tempObj.path_to_image,
      };
      const [_, { presigned_url }] = await VideoService.getPresignedUrl(obj);
      //   tempObj['url'] = presigned_url;
      this.$emit('updateImageObject', this.index, presigned_url);
      //   emit to update object in list
    },
  },
};
</script>
<style>
.image-card {
  padding: 1em;
  padding-bottom: 0;
}

.image-card .ant-card-meta-title {
  white-space: initial;
  text-align: center;
}
</style>
