import apiHelper from './index';

const updateImageAnnotation = async (payload, id, spinner = true) => {
  return await apiHelper.patch(
    `organization/task/annotation_image/${id}/`,
    payload,
    spinner
  );
};

const updateObjectsCount = async (payload, spinner = true) => {
  return await apiHelper.post(
    `organization/task/task_object/update_counts/`,
    payload,
    spinner
  );
};

const updateObjectsCoordinates = async (payload, spinner = true) => {
  return await apiHelper.put(
    `organization/task/task_object/update_delete`,
    payload,
    spinner
  );
};

const uploadObjectFile = async (annotation_id, payload, spinner = true) => {
  return await apiHelper.post(
    `/organization/task/file_upload/${annotation_id}/`,
    payload,
    spinner
  );
};

const getImageAnnotation = async (objectId, spinner = false) => {
  return await apiHelper.get(
    `organization/task/annotation_image/${objectId}/`,
    spinner
  );
};

export default {
  updateImageAnnotation,
  updateObjectsCount,
  updateObjectsCoordinates,
  uploadObjectFile,
  getImageAnnotation
};
