<template>
  <a-modal
    v-if="showConfirmDeleteRegionModal"
    v-model:visible="showConfirmDeleteRegionModal"
    @ok="handleOkDeleteModal()"
    @cancel="handleCancelDeleteModal"
  >
    <template #title>
      <div class="mr-2 mb-1">
        <ExclamationCircleOutlined
          style="font-size: 1rem; margin-left: -10px; margin-right: 2px; color: rgb(255, 210, 48);"
        />
        Delete Object
      </div>
    </template>
    <p>
      Do you want to delete this object?
    </p>
  </a-modal>
  <!-- <div v-if="isLoading" class="video-container">
    <spinner-component />
  </div> -->
  <div v-if="isLoading" class="spinner-overlay">
    <spinner-component />
    <div class="spinner-text">
      {{ iceState ? iceStateMessage : 'Waiting For connection...' }}
    </div>
  </div>
  <div v-show="!isLoading">
    <a-row :gutter="24" class="d-flex justify-content-between">
      <a-col span="6">
        <a-select
          id="webRtc-task-select"
          ref="task_input"
          placeholder="Select Operation"
          :value="task_id"
          show-search
          class="w-100"
          :options="options"
          :filter-option="filterOption"
          @change="onChangeTask"
        />
      </a-col>
      <a-col :span="4" class="d-flex align-items-center justify-content-end">
        <!-- <a-switch
          :disabled="!projector"
          class="mr-1"
          :style="{ backgroundColor: projectEnabled ? '#98fb98' : '#cd5c5c' }"
          @click="toggleProjector"
        >
          <template #checkedChildren><poweroff-outlined /></template>
          <template #unCheckedChildren><poweroff-outlined /></template>
        </a-switch> -->
        <a-tag color="success" v-if="projector">
          Projector Available
        </a-tag>
        <a-tag v-else color="error">
          Projector Unavailable
        </a-tag>
      </a-col>
    </a-row>

    <div class="video-container mt-2">
      <a-row :gutter="8" class="w-100 d-flex">
        <a-col span="6" class="d-flex flex-column">
          <ul
            v-if="isTaskDetailsLoading"
            id="inference-md-steps-list"
            class="list-group task-steps mt-2"
            style="height:65vh"
          >
            <li
              v-for="(step, index) in stepsList"
              :id="'inference-md-step-li-' + index"
              :key="index"
              class="list-group-item justify-content-between"
              :class="{
                'current-step': currentStepList?.includes(step.substepIndex),
                'list-group-item-primary font-weight-bold mt-1':
                  step.type === 'step',
                'font-weight-bold': selectedStepIndex === step.substepIndex,
                'green-region': ifStepSelected(step) && step.type !== 'step',
                disabled: (isDisableSteps && step.type !== 'step') || !projector
              }"
              @click="onClickStep(step.substepIndex, step.type)"
            >
              <span
                :id="'infer-step-li-' + index + '-name'"
                :class="{ 'ml-2': step.type === 'sub-step' }"
              >
                {{ step.name }}
              </span>
              <a-spin
                v-if="isPastingTrue && selectedStepIndex === step.substepIndex"
                class="mt-1"
                size="snall"
              />
              <span
                v-show="
                  isStepSelected &&
                    !isStepCopied &&
                    step.type === 'sub-step' &&
                    selectedStepIndex === step.substepIndex
                "
                style="cursor:pointer"
                @click="copyStepRegions"
              >
                Copy<CopyOutlined
              /></span>
              <span
                v-show="
                  isStepSelected &&
                    isStepCopied &&
                    step.type === 'sub-step' &&
                    selectedStepIndex === step.substepIndex
                "
                style="cursor:pointer"
                :class="{
                  disabled:
                    (isStepCopied &&
                      sourceStepIndex === selectedStepIndex &&
                      selectedStepIndex === step.substepIndex) ||
                    isPastingTrue
                }"
                @click="pasteStepRegions"
              >
                Paste<SnippetsOutlined
              /></span>
            </li>
          </ul>
        </a-col>

        <a-col span="12" class="p-1 d-flex flex-column align-items-center">
          <div>
            <video
              v-show="false"
              id="video"
              autoplay
              playsinline
              muted
              controls
            />
            <v-stage ref="stage" :config="stageSize">
              <v-layer ref="layer">
                <v-image ref="image" :config="imageConfig" />
                <v-rect
                  v-for="(rect, index) in rectangles"
                  :id="'region-rect-' + rect.name + '-' + index"
                  :key="rect.name"
                  :config="rect.toJSON()"
                />
                <v-line
                  v-for="(arrow, index) in arrows"
                  :id="'region-arrow-' + arrow.name + '-' + index"
                  :key="arrow.name"
                  :config="arrow.toJSON()"
                  :closed="true"
                />
                <v-text
                  v-for="(text, index) in texts"
                  :id="'region-arrow-' + text.name + '-' + index"
                  :key="text.name"
                  :config="text.toJSON()"
                />
                <v-circle
                  v-for="(circle, index) in circles"
                  :id="'region-circle-' + circle.name + '-' + index"
                  :key="circle.name"
                  :config="circle.toJSON()"
                />
              </v-layer>
            </v-stage>
          </div>

          <div
            class="stage-controls-wrapper mt-1"
            :class="{ disabled: !projector }"
          >
            <div class="d-flex align-items-center justify-content-evenly">
              <joystick-component
                v-model="joystickAndMovementButtonsDisable"
                :joystick-and-movement-buttons-disable="
                  joystickAndMovementButtonsDisable
                "
                :unit="unit"
                :selected-shape-type="selectedShapeType"
                @handleButtonEvents="handleButtonEvents"
                @handleMoveButtonEvents="handleMoveButtonEvents"
                @unitDirection="unitDirection"
              />
            </div>
          </div>
        </a-col>

        <a-col span="6" class="d-flex flex-column p-2">
          <a-card size="small" :class="{ disabled: !projector }">
            <a-space class="my-2">
              <a-input-group class="w-100 d-flex justify-content-between">
                <a-input
                  id="static-object-add-input"
                  v-model:value="objectDisplayName"
                  placeholder="Edit Object name"
                  width="50%"
                  class="mr-2"
                />
                <a-button
                  id="add-static-obj-btn"
                  type="primary"
                  :disabled="!isRegionSelected && !selectedShapeType"
                  @click="renameObject"
                >
                  Update Name
                </a-button>
              </a-input-group>
            </a-space>

            <a-list
              id="marked-region-obj-list"
              bordered
              item-layout="horizontal"
              :data-source="objectList"
              style="height:450px; overflow-y: auto;"
              size="small"
              class="mb-2 w-100"
            >
              <template #header>
                <div
                  class="d-flex justify-content-between align-items-center"
                  :class="{ disabled: !isStepSelected }"
                >
                  <h6>Step Regions</h6>
                  <a-dropdown
                    class="d-flex ml-auto"
                    type="primary"
                    :disabled="!isStepSelected"
                  >
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="1" @click="addShape(Region)">
                          <template #icon>
                            <custom-icon :icon="$customIcons.RegionIcon" />
                          </template>
                          Region
                        </a-menu-item>
                        <a-menu-item key="2" @click="addShape(Arrow)">
                          <ArrowRightOutlined />
                          Arrow
                        </a-menu-item>
                        <!-- <a-menu-item
                          key="6"
                          class="justify-content-between"
                          @click="addShape(Circle)"
                        >
                          <i class="bi bi-circle" />
                          Circle
                        </a-menu-item> -->
                        <a-menu-item key="3">
                          <a-upload
                            accept=".png, .jpg, .jpeg"
                            :file-list="fileList"
                            :multiple="false"
                            :before-upload="beforeUpload"
                            :custom-request="handleAddImage"
                            :remove="handleRemoveImage"
                            :show-upload-list="false"
                          >
                            <FileImageOutlined />
                            Image
                          </a-upload>
                        </a-menu-item>
                        <a-menu-item key="4">
                          <a-upload
                            accept=".gif"
                            :file-list="fileList"
                            :multiple="false"
                            :before-upload="beforeUpload"
                            :custom-request="handleAddImage"
                            :remove="handleRemoveImage"
                            :show-upload-list="false"
                          >
                            <FileGifOutlined />
                            Gif
                          </a-upload>
                        </a-menu-item>
                        <a-menu-item key="5" @click="addShape(Text)">
                          <FontColorsOutlined />
                          Text
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <div>
                      <a-button>
                        Add
                        <DownOutlined />
                      </a-button>
                    </div>
                  </a-dropdown>
                </div>
              </template>
              <template #renderItem="{ item, index }">
                <a-list-item
                  :id="'region-obj-' + index"
                  :class="{ 'green-region': ifRegionSelected(item) }"
                  @click="selectStepRegion(item, index)"
                >
                  <template #actions>
                    <span
                      v-if="
                        [ProjectorImage, Gif].includes(item.type) &&
                          item?.progress
                      "
                    >
                      <a-progress
                        type="circle"
                        :percent="item.progress"
                        :width="20"
                      />
                    </span>
                    <a-dropdown
                      v-if="[Region, Arrow, Text, Circle].includes(item.type)"
                      :trigger="['click']"
                      class="cursor-pointer"
                    >
                      <bg-colors-outlined />
                      <template #overlay>
                        <a-menu>
                          <a-menu-item
                            v-for="color in colors"
                            :key="color"
                            :disabled="!isStepSelected"
                            @click="
                              assignColorToShape(item, color.hex.toLowerCase())
                            "
                          >
                            <div
                              style="display: flex; align-items: center; justify-content: space-between;"
                            >
                              <span>
                                {{ color.name }}
                              </span>
                              <span class="ml-2">
                                <div
                                  :style="{
                                    backgroundColor: color.hex,
                                    width: '12px',
                                    height: '12px'
                                  }"
                                  class="border border-dark"
                                />
                              </span>
                            </div>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>

                    <!-- </span> -->
                    <span
                      v-if="
                        [ProjectorImage, Text, Arrow, Gif, Circle].includes(
                          item.type
                        )
                      "
                      :id="'region-plus-' + index"
                      class="cursor-pointer"
                      @click="scaleShape(item, index, true)"
                    >
                      <ZoomInOutlined />
                    </span>
                    <span
                      v-if="
                        [ProjectorImage, Text, Arrow, Gif, Circle].includes(
                          item.type
                        )
                      "
                      :id="'region-minus-' + index"
                      class="cursor-pointer"
                      @click="scaleShape(item, index, false)"
                    >
                      <ZoomOutOutlined />
                    </span>
                    <span
                      :id="'region-delete-' + index"
                      class="cursor-pointer"
                      @click="toggleShowConfirmDeleteRegionModal(item)"
                    >
                      <delete-outlined />
                    </span>
                    <span
                      :id="'region-rename-' + index"
                      class="cursor-pointer"
                      @click="selectObjectToEdit(item)"
                    >
                      <edit-outlined />
                    </span>
                    <span
                      v-if="[Gif, Arrow].includes(item.type)"
                      :id="'region-rename-' + index"
                      class="cursor-pointer"
                      :class="{ disabled: !selectedShapeName }"
                      @click="togglePlayGif(item)"
                    >
                      <PlayCircleOutlined v-if="!item.play" />
                      <PauseCircleOutlined v-else-if="item.play" />
                    </span>
                  </template>
                  <a-list-item-meta>
                    <template #title>
                      <span :id="'region-name-' + item.displayName">{{
                        item.displayName
                      }}</span>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>

            <div class="d-flex align-items-right">
              <a-button
                class="mr-2"
                type="default"
                :disabled="!isStepSelected"
                @click="handleDeselect"
              >
                Deselect
              </a-button>
              <a-button
                id="save-region-btn"
                class="mr-2"
                type="primary"
                :disabled="saveRegionButtonEnable"
                :loading="regionSaveLoading"
                @click="saveRegions"
              >
                Save Regions
              </a-button>
              <a-button
                id="sync-btn"
                class="mr-2"
                type="default"
                :disabled="!isStepCopied"
                @click="clearClipboard"
              >
                Clear Clipboard
              </a-button>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getSortedTask } from 'src/utils/task';
import joystickComponent from './joystickComponent.vue';
import SpinnerComponent from '../../../shared/Components/Spinner';
import httpClient from '../../../../service/httpClient';
import objectsMarkingMixin from '@/mixins/projectorRegionMarking';
import parseTaskList from '../../../shared/Helpers/parseTaskList';
import TaskService from 'src/services/tasks';
import spaceMixin from 'src/mixins/handleSpace';
import TaskObjectService from 'src/services/taskObjectsMarking';
import joystickButtonsMixin from 'src/mixins/joystickControlButtons';
import WebRtc from 'src/services/webrtc';
import { TYPES } from './ProjectiorRegion/helper';
import { MediaRTCConnection, RtcConnection } from './ProjectiorRegion/webrtc';
import { ShapeFactory } from './ProjectiorRegion/shapesHelper';
import S3Service from 'src/services/s3';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { message } from 'ant-design-vue';

import {
  DeleteOutlined,
  EditOutlined,
  PoweroffOutlined,
  DownOutlined,
  FileImageOutlined,
  ArrowRightOutlined,
  BgColorsOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FontColorsOutlined,
  CopyOutlined,
  SnippetsOutlined,
  FileGifOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';

export default {
  name: 'WebRtc',
  components: {
    joystickComponent,
    SpinnerComponent,
    EditOutlined,
    DeleteOutlined,
    PoweroffOutlined,
    DownOutlined,
    FileImageOutlined,
    ArrowRightOutlined,
    BgColorsOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    FontColorsOutlined,
    CopyOutlined,
    SnippetsOutlined,
    FileGifOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    ExclamationCircleOutlined
  },

  mixins: [objectsMarkingMixin, spaceMixin, joystickButtonsMixin],
  inject: ['toast', 'emitter'],
  props: {
    serialNumber: {
      type: String,
      required: true
    },
    taskList: {
      type: Array,
      required: true
    },
    deviceSettingUrl: {
      type: String,
      required: true
    },
    taskName: {
      type: String,
      required: true
    },
    currentTask: {
      type: String,
      required: true
    },
    projector: Boolean,
    iceOptions: {
      type: String,
      required: true
    },
  },
  emits: ['closeModal'],
  setup() {
    const info = text => {
      message.info(text);
    };
    const success = text => {
      message.success(text);
    };
    return {
      info,
      success
    };
  },
  data: function() {
    return {
      iceStateMessage: null,
      deleteSelectedObject: {},
      showConfirmDeleteRegionModal: false,
      uploadFile: '',
      sliderValue: 0,
      AddedImageConfig: {
        x: 20,
        y: 20,
        image: new Image(),
        width: 200,
        height: 200
      },
      colors: [
        { name: 'Red', hex: '#ff0000' },
        { name: 'Orange', hex: '#FFAC1C' },
        { name: 'Blue', hex: '#0000ff' },
        { name: 'Purple', hex: '#702963' },
        { name: 'Yellow', hex: '#FFFF00' },
        { name: 'White', hex: '#FFFFFF' }
      ],
      image: {
        imageObj: {},
        size: '',
        height: '',
        width: ''
      },
      fileList: [],
      objectList: [],
      joystickAndMovementButtonsDisable: true,
      saveRegionButtonEnable: true,
      projectEnabled: false,
      regionSaveLoading: false,
      isRegionSelected: false,
      isEditName: false,
      isStepSelected: false,
      isDisableSteps: false,
      isTaskDetailsLoading: false,
      isLoading: true,

      objectDisplayName: '',
      selectedShapeName: '',
      selectedShapeType: '',
      task_id: '',
      organization: localStorage.getItem('organization'),

      stageSize: {
        width: 640,
        height: 480
      },
      currentRegion: {},
      groups: {},
      stepsList: {},
      currentStepList: {},
      rtcConfiguration: {},
      taskStaticObjectsList: {},

      video: null,
      deviceMac: null,
      selectedStepIndex: null,
      currentObjectIndex: null,
      iceState: null,
      animation: null,
      pressTimer: null,
      pressInterval: null,
      mediaConnection: null,
      simpleRTC: null,
      dataChannel: null,
      fileChannel: null,

      unit: 5,
      movementUnit: 5,
      toastCount: 0,
      unitValue: 5,
      fileUploading: false,
      showProgress: false,
      uploadProgress: 0,
      downloadProgress: 0,
      isStepCopied: false,
      sourceStepIndex: '',
      targetStepIndex: '',
      isPastingTrue: false,
      buttonOutwards: true
    };
  },
  watch: {
    task_id: function(value) {
      this.getData(value);
    },
    iceState(value) {
      // if (value === 'disconnected') {
      //   this.$emit('closeModal');
      // } else if (value === 'connected') {
      //   this.isLoading = false;
      // }
      console.log('ice state->', value);
      console.log('watch icestate', value);
      if (value === 'failed') {
        console.log('icestate failed', value);
        this.iceStateMessage = 'Live Stream Failed';
        this.$emit('closeModal');
      } else if (value === 'disconnected') {
        console.log('icestate disconnected', value);
        // this.isLoading = true;
        // this.iceStateMessage = 'Slow network detected. Trying to reconnect...';
        // this.toast.info('Slow network detected. Trying to reconnect...');
      } else if (value === 'connected') {
        this.iceStateMessage = 'Live Stream Connected';
        this.isLoading = false;
      } else if (value === 'checking') {
        this.isLoading = true;
        this.iceStateMessage = 'Checking For Connection...';
      }
    },
    downloadProgress: function(value) {
      this.uploadProgress = 100;
      this.showProgress = false;
      this.fileUploading = false;
      if (value === 100) {
        this.showDownloadProgress = false;
        this.downloadProgress = 0;
        this.uploadProgress = 0;
      }
    }
  },
  unmounted() {
    this.groups = {
      [TYPES.rectangle]: {},
      [TYPES.arrow]: {},
      [TYPES.image]: {}
    };
  },

  methods: {
    toggleShowConfirmDeleteRegionModal(item) {
      this.showConfirmDeleteRegionModal = true;
      this.deleteSelectedObject = item;
    },
    handleOkDeleteModal() {
      this.filterObjectFromList(this.deleteSelectedObject);
      this.showConfirmDeleteRegionModal = false;
    },
    handleCancelDeleteModal() {
      this.showConfirmDeleteRegionModal = false;
      this.deleteSelectedObject = {};
    },
    updateSlider(value) {
      this.currentRegion[this.selectedStepIndex][
        this.selectedShapeName
      ].play = false;
      let animationValue =
        this.sliderValue > value
          ? this.sliderValue - (this.sliderValue - value)
          : value;
      this.sliderValue = value;
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const currentObj = shapeGroup[this.selectedShapeName];
      currentObj.maxAnimationValue = animationValue;
      currentObj.play = false;
      this.currentRegion[this.selectedStepIndex][
        this.selectedShapeName
      ].maxAnimationValue = animationValue;
      this.updateCurrentRegionSelectedShape();
    },
    updateCurrentRegionSelectedShape() {
      this.addSelectShapeToCurrentRegions();
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
      this.isRegionSelected = false;
      this.saveRegionButtonEnable = false;
    },

    togglePlayGif(item) {
      this.isRegionSelected = true;
      this.selectedShapeType = item.type;
      this.selectedShapeName = item.name;
      item.play = !item.play;
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const currentObj = shapeGroup[this.selectedShapeName];
      currentObj.play = item.play;
      shapeGroup[this.selectedShapeName].togglePlay(item.play);
      this.currentRegion[this.selectedStepIndex][this.selectedShapeName].play =
        item.play;
      this.updateCurrentRegionSelectedShape();
    },
    clearClipboard() {
      this.isStepCopied = false;
      this.sourceStepIndex = '';
      this.targetStepIndex = '';
    },
    copyStepRegions() {
      this.isStepCopied = true;
      this.sourceStepIndex = this.selectedStepIndex;
      this.toast.info('Step Regions Copied!');
    },
    async pasteStepRegions() {
      this.isPastingTrue = true;
      this.targetStepIndex = this.selectedStepIndex;
      let data = {
        id: this.task_id,
        source_step: this.sourceStepIndex,
        target_step: this.targetStepIndex
      };
      const [error, resp] = await TaskObjectService.copyRegions(data);
      if (resp) {
        await this.getTaskObjectList();
        this.toast.success('Step Regions and Media Copied Successfully.');
        this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
        this.onClickStep(this.targetStepIndex, 'sub-step');
      } else if (error) {
        this.toast.error(error.error);
      }
      this.isStepCopied = false;
      this.sourceStepIndex = '';
      this.targetStepIndex = '';
      this.isPastingTrue = false;
      this.saveRegionButtonEnable = false;
    },
    assignColorToShape(item, color) {
      this.isRegionSelected = true;
      this.selectedShapeType = item.type;
      item.fill = color;

      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const currentObj = shapeGroup[this.selectedShapeName];

      if (color) {
        currentObj.fill = color;
        this.objectList.splice(this.currentObjectIndex, 1, {
          ...currentObj
        });
        currentObj.fill = color;
      }
      shapeGroup[this.selectedShapeName].addColor(color);
      this.currentRegion[this.selectedStepIndex][
        this.selectedShapeName
      ].fill = color;
      this.updateCurrentRegionSelectedShape();
    },
    toggleProjector(value) {
      this.projectEnabled = value;
      this.sendDataThroughChannel({ projector: this.projectEnabled });
    },

    applyUnits() {
      if (Number(this.unitValue) === this.unit) {
        this.toast.warning(
          `Movement and resize units is already set on ${this.unitValue}`
        );
        return;
      }
      !this.buttonOutwards
        ? (this.unit = Number(this.unitValue) * -1)
        : (this.unit = Number(this.unitValue));
      this.movementUnit = Number(this.unitValue);
      this.toast.success('Object movement and resize units applied');
    },

    renameObject() {
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      let doesNameExist = Object.values(shapeGroup).some(
        ({ displayName }) => displayName === this.objectDisplayName
      );
      if (doesNameExist) {
        this.toast.error('Object of this name already exists');
        return;
      }
      const currentObj = shapeGroup[this.selectedShapeName];

      if (this.isEditName) {
        this.objectList.splice(this.currentObjectIndex, 1, {
          ...currentObj,
          displayName: this.objectDisplayName
        });
        currentObj.displayName = this.objectDisplayName;
        if ('text' in currentObj) currentObj.text = this.objectDisplayName;
      }
      this.addSelectShapeToCurrentRegions();
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
      this.objectDisplayName = '';
      this.isEditName = false;
      this.isRegionSelected = false;
      this.saveRegionButtonEnable = false;
    },

    selectObjectToEdit(obj) {
      this.isRegionSelected = true;
      this.objectDisplayName = obj.displayName;
      this.selectedShapeType = obj.type;
      this.isEditName = true;
    },

    addLongPressToJoystickButtons() {
      this.joystickButtons.forEach(({ propsData }) => {
        const { id } = propsData;
        const btn = document.getElementById(id);
        this.addLongPressToButton(btn, this.handleButtonEvents, [
          id,
          this.unit
        ]);
      });
    },

    addLongPressToMovementButtons() {
      this.movementButtons.forEach(({ propsData, ...button }) => {
        const { id } = propsData;
        const btn = document.getElementById(id);
        this.addLongPressToButton(btn, this.handleMoveButtonEvents, [
          button.action,
          button.direction * this.movementUnit
        ]);
      });
    },

    addLongPressToButton(button, handler, args, delay = 400, interval = 50) {
      button.onmousedown = e =>
        this.longPressButton(e, delay, interval, handler, args);
      button.onmouseup = this.cancelLongPress;
      button.onmouseout = this.cancelLongPress;
    },

    longPressButton(e, delay, interval, handler, args) {
      if (e.type === 'click' && e.button !== 0) return;

      const self = this;
      const setPressInterval = () => {
        self.pressInterval = setInterval(handler, interval, ...args);
        handler(...args);
      };
      if (!this.pressTimer)
        this.pressTimer = setTimeout(setPressInterval, delay);
    },

    cancelLongPress() {
      if (this.pressTimer) {
        clearTimeout(this.pressTimer);
        this.pressTimer = null;
      }
      if (this.pressInterval) {
        clearInterval(this.pressInterval);
        this.pressInterval = null;
      }
    },
    unitDirection(buttonOutwards) {
      this.buttonOutwards = buttonOutwards;
      this.unit = -1 * this.unit;
    },

    filterObjectFromList(item) {
      this.selectedShapeName = item.name;
      this.deleteObject(item);
      this.objectList = this.objectList.filter(o => o.id !== item.id);
      this.fileList.splice(
        this.fileList.findIndex(a => a.id === item.name),
        1
      );
      this.saveRegionButtonEnable = false;
    },

    renderExistingStaticObjectList() {
      let objList = this.taskStaticObjectsList[
        'cordinates_of_regions_for_projector'
      ];
      if (!objList) return;

      let stepObjectList = objList[this.selectedStepIndex];
      if (!stepObjectList) stepObjectList = [];
      this.objectList = stepObjectList
        .map((shape, index) =>
          ShapeFactory.createShape(shape.type, {
            ...shape,
            id: index,
            scaleDirection: 'Up'
          })
        )
        .sort((a, b) => a.id - b.id);

      this.groups = this.objectList.reduce((finalObj, shape) => {
        finalObj[shape.type][shape.name] = shape;
        return finalObj;
      }, this.createGroups());
    },

    createGroups() {
      return Object.values(TYPES).reduce((obj, key) => {
        obj[key] = {};
        return obj;
      }, {});
    },

    async onClickStep(stepIndex, stepType) {
      this.objectDisplayName = '';
      this.selectedShapeName = '';
      this.selectedShapeType = '';
      this.isRegionSelected = false;
      this.currentObjectIndex = null;

      if (stepType === 'step') return;
      this.isStepSelected = true;
      this.selectedStepIndex = stepIndex;
      this.renderExistingStaticObjectList();
      this.currentRegion[this.selectedStepIndex] = this.getCurrentRegions();
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
    },

    getCurrentRegions() {
      let currentRegions = {};
      const shapeTypes = Object.keys(this.groups);
      shapeTypes.forEach(shapeType => {
        Object.entries(this.groups[shapeType]).forEach(([name, shape]) => {
          currentRegions[name] = ShapeFactory.createShape(shape.type, shape);
          currentRegions[name].scale(
            this.stageSize.width,
            this.stageSize.height,
            'down'
          );
        });
      });
      return currentRegions;
    },

    async getTaskObjectList() {
      const [_, data] = await TaskService.getProjectorRegions(
        this.task_id,
        false
      );
      if (data) this.taskStaticObjectsList = data;
      else
        this.taskStaticObjectsList['cordinates_of_regions_for_projector'] = {};
    },

    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 300;
      if (!isLt2M) {
        this.toast.error('Image must smaller than 300MB!');
      }
      return isLt2M;
    },

    scaleShape(shape, index, scale) {
      let value = 0;
      if (
        shape.type === this.ProjectorImage ||
        shape.type === this.Arrow ||
        shape.type === this.Gif
      )
        value = scale ? 0.1 : -0.1;
      else if (shape.type === this.Text || shape.type === this.Circle)
        value = scale ? 2 : -2;
      this.selectStepRegion(shape, index);
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const objToUpdate = shapeGroup[this.selectedShapeName];
      objToUpdate.updateScale(value);
      this.saveRegionButtonEnable = false;
      this.addSelectShapeToCurrentRegions();
    },

    async handleAddImage(obj) {
      const { file } = obj;
      const key = `${this.task_name}/Projector/${this.selectedStepIndex}/${file.name}`;
      this.saveRegionButtonEnable = false;
      this.selectedShapeType = file.type.includes('/gif')
        ? this.Gif
        : this.ProjectorImage;
      this.image.size = file.size;
      const newShapeID = this.objectList.length;
      const newShape = ShapeFactory.createShape(this.selectedShapeType, {
        id: newShapeID,
        displayName: file.name,
        size: file.size,
        s3Key: key
      });
      newShape['fileObj'] = file;
      file['id'] = newShape.name;

      this.fileList.push(file);
      this.objectList = [...this.objectList, newShape];
      await this.saveFileViaPresignedURL(file);
      this.currentShape[newShape.name] = newShape;
      const shapeProxy = ShapeFactory.createShape(
        this.selectedShapeType,
        newShape.toJSON()
      );
      shapeProxy.scale(this.stageSize.width, this.stageSize.height, 'down');

      this.currentRegion[this.selectedStepIndex][newShape.name] = shapeProxy;

      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);

      return;
    },
    handleRemoveImage(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    addShape(shapeType) {
      this.saveRegionButtonEnable = false;
      this.selectedShapeType = shapeType;
      const newShapeID = this.objectList.length;
      const newShape = ShapeFactory.createShape(shapeType, { id: newShapeID });
      this.objectList = [...this.objectList, newShape];
      this.currentShape[newShape.name] = newShape;

      const shapeProxy = ShapeFactory.createShape(shapeType, newShape.toJSON());
      shapeProxy.scale(this.stageSize.width, this.stageSize.height, 'down');

      this.currentRegion[this.selectedStepIndex][newShape.name] = shapeProxy;
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
    },

    updateStepRegions(region) {
      region = ShapeFactory.createShape(
        region.type,
        JSON.parse(JSON.stringify(region))
      );
      this.objectList.push(region);
      const shapeProxy = ShapeFactory.createShape(region.type, region.toJSON());
      shapeProxy.scale(this.stageSize.width, this.stageSize.height, 'down');
      this.currentRegion[this.selectedStepIndex][region.name] = shapeProxy;
    },

    deleteObject({ type }) {
      delete this.groups[type][this.selectedShapeName];
      delete this.currentRegion[this.selectedStepIndex][this.selectedShapeName];
      this.selectedShapeName = '';
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
    },

    async showStream() {
      this.rtcConfiguration = await WebRtc.getRTCServers(this.iceOptions);
      this.simpleRTC = new RtcConnection(
        this.serialNumber,
        this.organization,
        this.fcmToken,
        this.rtcConfiguration
      );
      await this.simpleRTC.connectToRemotePeer();
      this.mediaConnection = new MediaRTCConnection(
        this.serialNumber,
        this.organization,
        this.fcmToken,
        this.video,
        this.rtcConfiguration,
        this.setIceState
      );
      this.mediaConnection.connectToRemotePeer();

      this.dataChannel = this.simpleRTC.getJSONChannel();
      this.fileChannel = this.simpleRTC.getFileChannel();
      const self = this;
      this.fileChannel.onmessage = function({ data }) {
        data = JSON.parse(data);
        self.downloadProgress = Math.round(
          (data['offset'] / data['total']) * 100
        );
        self.updateItemProgress(data['file_name'], self.downloadProgress);
        if (self.downloadProgress === 100) {
          self.success(`File ${data['file_name']} download complete`);
        }
      };
    },

    sendFileToRemotePeer(file) {
      let self = this;
      return new Promise((resolve, reject) => {
        let fileSize = file.size;
        let chunkSize = 16 * 1024;
        let offset = 0;

        const readEventHandler = e => {
          const { error, result } = e.target;
          if (!error) {
            offset += result.byteLength;
            self.fileChannel.send(result);
          } else {
            reject(`Error ${error}`);
            return;
          }
          if (offset >= fileSize) {
            self.fileChannel.send(JSON.stringify({ start: false }));
            resolve(true);
            return;
          }
          chunkReaderBlock(offset, chunkSize, file);
        };

        const chunkReaderBlock = (_offset, length, _file) => {
          let r = new FileReader();
          let blob = _file.slice(_offset, length + _offset);
          r.onload = readEventHandler;
          r.readAsArrayBuffer(blob);
        };
        self.fileChannel.send(
          JSON.stringify({
            start: true,
            fileName: file.name,
            fileSize: file.size
          })
        );
        chunkReaderBlock(offset, chunkSize, file);
      });
    },

    async sendDataThroughChannel(data) {
      if (this.dataChannel.readyState !== 'open') return;

      this.dataChannel.send(JSON.stringify(data));

      Object.values(data).forEach(obj => {
        if (obj.type === TYPES.image || obj.type === TYPES.gif)
          this.fileChannel.send(JSON.stringify(obj));
      });
    },

    setIceState: function(value) {
      this.iceState = value;
    },

    getData(taskId) {
      httpClient.getData('organization/task/' + taskId + '/').then(data => {
        const temp = parseTaskList(data.task_detail);
        this.stepsList = temp;
        this.currentStepList = temp;
        this.isTaskDetailsLoading = true;
        this.task_name = data.taskName;
      });
    },
    onChangeTask(task_id) {
      this.task_id = task_id;
      this.$refs.task_input.blur();
      this.getTaskObjectList();
    },

    handleDeselect() {
      this.selectedShapeName = '';
      this.selectedShapeType = '';
      this.isRegionSelected = false;
      this.objectDisplayName = '';
      this.joystickAndMovementButtonsDisable = true;
      Object.keys(this.currentRegion[this.selectedStepIndex]).forEach(k => {
        this.currentRegion[this.selectedStepIndex][k]['selected'] = false;
      });
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
    },
    play() {
      this.video.play();
      this.animation.start();
    },
    pause() {
      this.video.pause();
      this.animation.stop();
    },

    addSelectShapeToCurrentRegions() {
      const { [this.selectedShapeName]: objToUpdate } = this.currentRegion[
        this.selectedStepIndex
      ];
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      objToUpdate.deepCopy(shapeGroup[this.selectedShapeName]);
      objToUpdate.selected = true;
      objToUpdate.scale(this.stageSize.width, this.stageSize.height, 'down');
    },
    resetToastCount() {
      if (this.toastCount === 1) {
        setTimeout(() => {
          this.toastCount = 0;
        }, 2000);
      }
    },
    handleButtonEvents(action, value) {
      if (!this.buttonOutwards && value > 0) {
        value = Number(value) * -1;
      }
      if (
        !this.handleNodeButtonEvents(this.selectedShapeName, action, value) &&
        this.toastCount < 1
      ) {
        if (action === 'down' || action === 'up') {
          if (this.buttonOutwards) {
            this.toast.warning('Height cannot be increased further', {
              timeout: 4000
            });
          } else {
            this.toast.warning('Height cannot be decreased further', {
              timeout: 4000
            });
          }
        } else {
          if (this.buttonOutwards) {
            this.toast.warning('Width cannot be increased further', {
              timeout: 4000
            });
          } else {
            this.toast.warning('Width cannot be decreased further', {
              timeout: 4000
            });
          }
        }

        this.toastCount += 1;
        this.resetToastCount();
      }

      this.addSelectShapeToCurrentRegions();
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
      this.saveRegionButtonEnable = false;
    },
    handleMoveButtonEvents(action, value) {
      const { [this.selectedShapeType]: shapeGroup } = this.groups;
      const currentObj = shapeGroup[this.selectedShapeName];
      if (
        !this.handleNodeMoveButtonEvents(
          this.selectedShapeName,
          action,
          value
        ) &&
        this.toastCount < 1
      ) {
        this.toast.warning('Region cannot be pointed out of projector region', {
          timeout: 4000
        });
        this.toastCount += 1;
        this.resetToastCount();
      }
      this.addSelectShapeToCurrentRegions();
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
      this.saveRegionButtonEnable = false;
    },

    markStepRegionAsSelected(regions, stepIndex, selectedShape) {
      const allRegions = Object.keys(regions[stepIndex]);
      allRegions.forEach(
        regionName =>
          (regions[stepIndex][regionName].selected =
            regionName === selectedShape)
      );
    },

    selectStepRegion(obj, index) {
      this.selectedShapeType = obj.type;
      this.joystickAndMovementButtonsDisable = false;
      this.selectedShapeName = this.currentShape ? obj.name : '';
      this.markStepRegionAsSelected(
        this.currentRegion,
        this.selectedStepIndex,
        this.selectedShapeName
      );
      this.currentObjectIndex = index;
      this.sliderValue =
        obj.type === this.Arrow
          ? this.currentRegion[this.selectedStepIndex][this.selectedShapeName]
              .maxAnimationValue
          : 0;
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    createAWSFormData(file, data) {
      let awsformData = new FormData();
      Object.keys(data.fields).forEach(key => {
        awsformData.append(key, data.fields[key]);
      });
      awsformData.append('file', file);
      return awsformData;
    },

    async saveFileViaPresignedURL(fileObj) {
      const data = await this.getProjectorFileUploadURL(fileObj.name);
      if (!data) {
        this.toast.error('Could not access storage');
        return;
      }
      let awsformData = this.createAWSFormData(fileObj, data);
      await this.uploadFileToS3(data.url, awsformData);
    },
    async uploadFileToS3(url, data) {
      const fileName = data.get('file')?.name;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      };
      this.info(`Uploading ${fileName} to Cloud`);
      await axios.post(url, data, {
        headers,
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          this.updateItemProgress(fileName, percent);
        }
      });
      this.success(`File ${fileName} uploaded successfully`);
      const self = this;
      setTimeout(() => {
        self.info(`Downloading ${fileName} to Machine`);
      }, 2000);
    },
    updateItemProgress(itemName, progress) {
      if (!itemName) return;
      let index = this.objectList.findIndex(
        item => item.displayName === itemName
      );
      if (Object.hasOwn(this.objectList[index], 'progress')) {
        this.objectList[index].progress = progress;
      }
    },
    async getProjectorFileUploadURL(fileName) {
      let payload = {
        bucket: `${localStorage.getItem('organization')}-training`,
        key: `${this.task_name}/Projector/${this.selectedStepIndex}/${fileName}`
      };
      const [error, data] = await S3Service.getPresignedPostURL(payload, false);
      return error ? error : data;
    },
    updatePlayForInference() {
      const {
        [this.selectedStepIndex]: currentStepRegions
      } = this.currentRegion;
      let shapes = Object.values(currentStepRegions).map(shape => {
        if ([this.ProjectorImage, this.Gif, this.Arrow].includes(shape.type)) {
          if (Object.hasOwn(shape, 'play')) shape.play = true;
        }
        return shape;
      });
      if (!this.taskStaticObjectsList.cordinates_of_regions_for_projector)
        this.taskStaticObjectsList.cordinates_of_regions_for_projector = {};
      this.taskStaticObjectsList['cordinates_of_regions_for_projector'][
        this.selectedStepIndex
      ] = [...shapes];
    },

    saveNewObjects() {
      const {
        [this.selectedStepIndex]: currentStepRegions
      } = this.currentRegion;
      let rects = Object.values(currentStepRegions).map(rect => rect.toJSON());
      if (!this.taskStaticObjectsList.cordinates_of_regions_for_projector)
        this.taskStaticObjectsList.cordinates_of_regions_for_projector = {};
      this.taskStaticObjectsList['cordinates_of_regions_for_projector'][
        this.selectedStepIndex
      ] = [...rects];
    },

    async updateProjectorRegions() {
      const payload = JSON.stringify(this.taskStaticObjectsList);
      const [error] = await TaskObjectService.updateProjectorRegionsJson(
        payload,
        this.task_id
      );
      if (error) {
        console.log({ error });
        this.toast.error('Error while updating projector regions');
      }
    },

    async saveRegions() {
      this.toast.info('Updating the Projector Regions!');
      this.regionSaveLoading = true;
      this.isStepSelected = false;
      this.isDisableSteps = true;
      this.saveNewObjects();
      this.updatePlayForInference();
      await this.updateProjectorRegions();
      this.toast.success('New Regions Saved Successfully');
      this.objectDisplayName = '';
      this.isRegionSelected = false;
      this.regionSaveLoading = false;
      this.saveRegionButtonEnable = true;
      this.isStepSelected = true;
      this.isDisableSteps = false;
    },

    ifRegionSelected(item) {
      return item.name === this.selectedShapeName;
    },
    ifStepSelected(step) {
      return this.selectedStepIndex === step.substepIndex;
    },
    async syncTask() {
      this.sendDataThroughChannel(this.currentRegion[this.selectedStepIndex]);
      const data = this.currentRegion[this.selectedStepIndex];
      const self = this;
      Object.keys(data).forEach(function(key) {
        if (data[key].type === 'Image') {
          self.fileChannel.send(JSON.stringify(data[key].displayName));
        }
      });
    }
  },
  created() {
    this.deviceMac = this.$route.params.device_id || this.serialNumber;
    this.options = getSortedTask(this.taskList);
    this.groups = this.createGroups();
  },
  computed: {
    ...mapGetters(['organization', 'fcmToken']),
    rectangles() {
      return this.groups[TYPES.rectangle];
    },
    arrows() {
      return this.groups[TYPES.arrow];
    },
    images() {
      return this.groups[TYPES.image];
    },
    texts() {
      return this.groups[TYPES.text];
    },
    gifs() {
      return this.groups[TYPES.gif];
    },
    currentShape() {
      return this.groups[this.selectedShapeType];
    },
    Arrow() {
      return TYPES.arrow;
    },
    Region() {
      return TYPES.rectangle;
    },
    ProjectorImage() {
      return TYPES.image;
    },
    Text() {
      return TYPES.text;
    },
    Gif() {
      return TYPES.gif;
    },
    imageConfig() {
      return {
        image: this.video,
        x: 0,
        y: 0,
        width: 640,
        height: 480
      };
    }
  },

  mounted() {
    this.video = document.getElementById('video');
    this.layer = this.$refs.image.getStage().getLayer();
    this.animation = new Konva.Animation(() => {}, this.layer);
    this.video.addEventListener('canplay', () => {
      this.play();
    });
    this.showStream();
    this.addLongPressToJoystickButtons();
    this.addLongPressToMovementButtons();
  },
  beforeUnmount() {
    this.emitter.off(this.serialNumber, this.simpleRTC.connectToRemotePeer);
    this.mediaConnection.close();
    this.simpleRTC.close();
  }
};
</script>

<style scoped>
.video-container {
  display: flex;
  align-items: center;
  /* position: relative; */
}
.task-steps {
  height: 50vh;
  overflow-y: auto;
}

#remoteVideo {
  height: 65vh;
}
.heading {
  margin-bottom: 0.5em;
}
.list-container {
  overflow-y: auto;
  height: 74vh;
}
.current-step {
  font-weight: bold;
}
.list-group-item {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0.4em;
  font-size: 0.73em;
}

.disabled {
  /* This makes it not clickable */
  pointer-events: none;
  opacity: 0.6; /* This grays it out to look disabled */
}

/* .abs-position {
  position: absolute;
} */

.cursor-default {
  cursor: default;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-controls-wrapper {
  position: relative;
  width: 100%;
}

.abs-label {
  position: absolute;
  left: 0;
  top: 0;
}

.green-region {
  font-weight: bolder;
  background-color: rgb(210, 248, 210);
}

.spinner-overlay {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  /* Semi-transparent background for the overlay */
  /* background: rgba(
    255,
    255,
    255,
    0.8
  );  */
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1;  */
  text-align: center;
  flex-direction: column;
}
.spinner-text {
  margin-top: 10px; /* Add some spacing between the spinner and text */
}
</style>
